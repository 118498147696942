<template>
	<div id="tu-information-subject">
		<el-dialog
	    v-model="dialogVisible"
	    title="Subjects"
	    width="45%"
	  >
	  	<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
				<el-tab-pane v-for="item, index in subjectData.group" :label="item.name" :name="item.name">
					<div v-for="it, i in item.children">
						<div style="font-weight: 600;font-size: 18px;line-height: 1.8;">{{ subjectData.subject[it].name }}</div>
						<div>
							<!-- <el-tag class="mr-2" effect="dark">Tag 1</el-tag>
							<el-tag class="mr-2" effect="plain">Tag 1</el-tag> -->
							<el-tag class="mr-2" v-for="it2, index2 in subjectData.subject[it].children" 
								@click="selectSubjectLevel(it2.id)" :effect="isInSubject(it2.id)">
								{{ it2.name }}
							</el-tag>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TeacherApi from '@/api/teacher'

export default {
	name: "tu-information-subject",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		
	},
	data() {
		return {
			dialogVisible: false,
			content: [],
			activeName: 'English'
		}
	},
	watch: {
		profile() {
			this.content = this.profile.subject || []
		}
	},
	computed: {
		...mapGetters([
			'subjectData'
		])
	},
	created() {
		this.$store.dispatch('GetSubjectData')
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		isInSubject(id) {
			if(this.content.indexOf(id) != -1) {
				return 'dark';
			}
			return 'plain';
		},
		selectSubjectLevel(id) {
			let index = this.content.indexOf(id);
			if(index !== -1) {
				this.content.splice(index, 1);
			} else {
				this.content.push(id);
			}
		},
		handleSaveOperation() {
			if(!!!this.content) {
				this.$message.error("Relevant information is required");
				return ;
			}
			let params = {
				subject: this.content
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-subject {
	.el-dialog {
		.el-dialog__body {
			padding-top: 5px;
		}
	}

	.el-dialog__footer {
		text-align: center;
	}

	.mr-2 {
		margin: 5px 5px 5px 0;
		cursor: pointer;
	}
}
</style>