<template>
	<div id="tu-upload">
		<el-upload
			class="upload"
			ref="upload"
			:multiple="multiple"
			:data="data"
			:name="name"
			:show-file-list="showFileList"
			:drag="dragMode"
			:thumbnail-mode="thumbnailMode"
			:limit="limit"
			:file-list="fileList"
			:headers="headers"
			:action="actionUrl"
			:auto-upload="autoUpload"
			:on-preview="handleOnPreview"
			:on-remove="handleOnRemove"
			:on-success="handleOnSuccess"
			:on-error="handleOnError"
			:on-progress="handleOnProgress"
			:on-change="handleOnChange"
			:before-upload="handleBeforeUpload"
			:before-remove="handleBeforeRemove">
			<div v-if="showType=='drag'" class="drag-upload">
				<el-image v-if="imageUrlPath" :src="imageUrlPath" fit="fit" class="avatar"></el-image>
				<el-icon v-else class="el-icon--upload"><i class="fa fa-cloud-upload"></i></el-icon>
				<div class="el-upload__text">
					Drop file here or <em>click to upload</em>
				</div>
			</div>
			<div v-else-if="showType=='avatar'" class="avatar-upload">
				<el-image v-if="imageUrlPath" :src="imageUrlPath" fit="cover" class="avatar"></el-image>
				<el-icon v-else class="avatar-uploader-icon"><i class="fa fa-plus"></i></el-icon>
			</div>
			<div v-else>
				<el-button size="small" type="primary" :disabled="uploadDisabled">Click to upload</el-button>
			</div>

			<template v-if="showTip" #tip>
				<div class="el-upload__tip">
					jpg/png files with a size less than 2048kb
				</div>
			</template>
		</el-upload>
	</div>
</template>
<script>
import CommonApi from '@/api/common'

export default {
	name: 'TuUpload',
	props: {
		// 展现方式，button上传按钮; drag拖拽上传; avatar头像上传
		showType: {
			type: String,
			default: function() {
				return "button"
			}
		},
		// 是否显示 Tip
		showTip: {
			type: Boolean,
			default: function() {
				return false
			}
		},
		imageUrl: {
			type: String,
			default: function() {
				return ''
			}
		},
	  action: {
	  	type: String,
	  	default: function() {
	  		return ''
	  	}
	  },
	  multiple: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  },
	  data: {
	  	type: Object,
	  	default: function() {
	  		return {}
	  	}
	  },
	  name: {
	  	type: String,
	  	default: function() {
	  		return 'file'
	  	}
	  },
	  showFileList: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  },
	  drag: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  },
	  thumbnailMode: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  },
	  fileList: {
	  	type: Array,
	  	default: function() {
	  		return []
	  	}
	  },
	  limit: {
	  	type: Number,
	  	default: function() {
	  		return 1000
	  	}
	  },
	  autoUpload: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  },
	  uAction: {
	  	type: String,
	  	default: function() {
	  		return 'file'
	  	}
	  },
	  // 是否通过分片进行上传处理
	  splitOrNot: {
	  	type: Boolean,
	  	default: function() {
	  		return false
	  	}
	  }
	},
	data() {
		return {
			uploadFile: null,
			fileRaw: null,
			okData: '',
			uploadDisabled: false
		}
	},
	created() {
		
	},
	computed: {
		actionUrl() {
			return 'aaa' + this.action;
		},
		headers() {
			// 设置上传的属性
			return {}
		},
		dragMode() {
			if(this.drag) {
				return true
			}
			if(this.showType == 'drag') {
				return true
			}
			return false
		},
		imageUrlPath() {
			if(this.uploadFile) {
				return this.uploadFile
			} else if(this.imageUrl) {
				return this.imageUrl
			}
			return false;
		}
	},
	methods: {
		// 点击文件列表中已上传的文件时的钩子
		handleOnPreview(file) {
			// console.log('ddd', 'handleOnPreview', file)
		},
		// 文件列表移除文件时的钩子
		handleOnRemove(file, fileList) {
			// console.log('ddd', 'handleOnRemove', file)
		},
		// 文件上传成功时的钩子
		handleOnSuccess(response, file, fileList) {
			// console.log('ddd', 'handleOnSuccess', file)
		},
		// 文件上传失败时的钩子
		handleOnError(err, file, fileList) {
			// console.log('ddd', 'handleOnError', file)
		},
		// 文件上传时的钩子
		handleOnProgress(event, file, fileList) {
			// console.log('ddd', 'handleOnProgress', file)
		},
		// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
		handleOnChange(file, fileList) {
			this.okData = ''
			this.fileRaw = file.raw

			// 在此处理分片上传
			if(this.splitOrNot) {
				this.UploadBySplit(file, fileList)
			} else {
				this.changeFileToBase64(file.raw).then(res => {
					this.$emit('start', file.raw);
					this.uploadFile = res;
					this.startUpload()
				})
			}
		},
		// 上传文件之前的钩子，参数为上传的文件。 若返回 false 或者返回 Promise 且被 reject，则终止上传。
		handleBeforeUpload(file) {
			// console.log('ddd', 'handleBeforeUpload', file)
		},
		// 删除文件之前的钩子，参数为上传的文件和文件列表。 若返回 false 或者返回 Promise 且被 reject，则终止删除。
		handleBeforeRemove(file, fileList) {
			// console.log('ddd', 'handleBeforeRemove', file)
		},
		startUpload() {
			if(this.okData) {
				return this.okData
			}
			if(!!!this.uploadFile) {
				return false;
			}
			let params = {
				action: this.uAction,
				name: this.fileRaw.name,
				file: this.uploadFile
			}
			CommonApi.upload(params).then(res => {
				this.okData = res.data;
				this.okData.uid = this.fileRaw.uid;
				this.$emit('success', this.okData);
				return this.okData
			})
		},
		changeFileToBase64(file) {
			return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let fileResult = '';
				reader.readAsDataURL(file)
				reader.onload = function() {
					fileResult = reader.result
				}
				reader.onerror = function(error) {
					reject(error)
				}
				reader.onloadend = function() {
					resolve(fileResult)
				}
			})
		},
		UploadBySplit(file, fileList) {
			// 限制 50MB 文件
			if (file.size > 20 * 1024 * 1024) {
				this.$message.warning(this.$t('Upload size limit 20M'));
			}

			// 禁用上传按钮
			// this.uploadDisabled = true
			this.$emit('start', file.raw);
			let identifier = parseInt((new Date()).getTime() / 1000)
			this.UploadBySplitSon(file.raw, identifier, 0)
		},
		UploadBySplitSon(file, identifier, i) {
			let that = this
			// 分片，每片大小25KB
			let chunkSize = 25000;
			// 总大小
			let totalSize = file.size;
			// 总分片数
			let totalChunks = Math.ceil(totalSize/chunkSize)

			if (i == totalChunks) {
				that.$emit("progress", {uid: file.uid, progress: 100});
				// this.$emit('successs', file.raw);
				return
			}

			let start = i * chunkSize
			let stop = Math.min(totalSize, (start + chunkSize))

			let fileData = file.slice(start, stop)

			let reader = new FileReader();
			reader.readAsBinaryString(fileData)
			reader.onload = function(e) {
				let formData = new FormData();
				// 上传文件
				formData.append("file", fileData);
				// 当前分片号
				formData.append("chunkNumber", i + 1)
				// 分片大小
				formData.append("chunkSize", chunkSize)
				// 当前块大小
				formData.append("currentChunkSize", fileData.size)
				// 总大小
				formData.append("totalSize", totalSize)
				// 唯一标示
				formData.append("identifier", identifier)
				// 文件名称
				formData.append("filename", file.name)
				// 文件类型
				formData.append("type", file.type)
				// 总分片数量
				formData.append("totalChunks", totalChunks)
				// 
				formData.append("action", that.uAction)

				return new Promise((resolve, reject) => {
					let config = {
						headers: {
							'Content-Type': 'multiple/form-data; charset=utf8; boundary="another cool boundary";',
						}
					}

					CommonApi.uploadSplit(formData, config.headers).then(res => {
						// 判断是否正常
						// 上传进度
						let progress = 100;
						if ((i + 1) < totalChunks) {
							progress = Math.ceil((i / totalChunks)*100)
						} else {
							// 完成上传，
							that.uploadDisabled = false;
							that.okData = res.data;
							that.okData.uid = file.uid;
							that.okData.progress = progress;
							that.$emit('success', that.okData);
						}

						that.$emit("progress", {uid: file.uid, progress: progress});
						// 调用
						
						i = i + 1
						that.UploadBySplitSon(file, identifier, i)
					})
				})
			}
		}
	}
}
</script>
<style lang="less">
#tu-upload {
	.upload {
		.avatar-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 178px;
				height: 178px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				flex-direction: row;
			}	

			.avatar {
				width: 178px;
				height: 178px;
				display: block;

				img {
					// width: unset;
				}
			}
		}

		.drag-upload {
			.avatar {
				width: 100%;
				height: 178px;
				display: block;

				img {
					width: unset;
				}
			}
		}

		.avatar-upload:hover {
			border-color: #409eff;
		}
	}
}
</style>