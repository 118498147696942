<template>
	<div id="tu-information-avatar">
		<el-dialog
	    v-model="dialogVisible"
	    title="Upload your picture"
	    width="26%"
	  >
		<div class="avatar">
			<TuUpload show-type="avatar" :image-url="content" u-action="avatar" ref="avatar" />
			<div style="margin-top: 5px;">{{ $t('click-upload') }}</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'
import TuUpload from '@/components/TuUpload'

export default {
	name: "tu-information-avatar",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		TuUpload
	},
	data() {
		return {
			dialogVisible: false,
			content: ''
		}
	},
	watch: {
		profile() {
			this.content = this.profile.avatar_url || ''
		}
	},
	created() {
		
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		handleSaveOperation() {
			let avatar = this.$refs.avatar.startUpload();
			if(!!!avatar) {
				this.$message.error(this.$t('need-upload-file"'));
				return ;
			}
			let params = {
				avatar_url: avatar.code
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
				this.$store.dispatch('GetUserInfo');
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-avatar {
	.avatar {
		text-align: center;
	}
	.el-dialog__footer {
		text-align: center;
	}
}
</style>