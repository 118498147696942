<template>
	<div id="tu-information-background_check">
		<el-dialog
	    v-model="dialogVisible"
	    title="Background check"
	    width="42%">
				<p class="p">
					You don't need a background check to tutor with us but our tutors with the 'I have a background check' badge on their profiles receive more enquiries than those without. To get your badge, please upload a copy of your enhanced background check (DBS or PVG). You can also provide us with DBS Update Service details so that your background check and badge won't expire.
				</p>
				<p class="p">
					We will request an update from the DBS update service periodically to keep your DBS status updated.
				</p>
				<p class="p">
					Your DBS certificate and personal details will not be visible to other users.
				</p>
					<div class="background_check">
						<TuUpload show-type="drag" u-action="teacher" ref="background_check" />
				</div>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
						<el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
					</span>
				</template>
	  	</el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'
import TuUpload from '@/components/TuUpload'

export default {
	name: "tu-information-background_check",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		TuUpload
	},
	data() {
		return {
			dialogVisible: false,
			content: ''
		}
	},
	created() {
		
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		handleSaveOperation() {
			let background_check = this.$refs.background_check.startUpload();
			if(!!!background_check) {
				this.$message.error(this.$t('need-upload-file"'));
				return ;
			}
			let params = {
				background_check: background_check.code
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-background_check {
	.el-dialog {
		.el-dialog__body {
			padding-top: 5px;
		}
	}

	.el-dialog__footer {
		text-align: center;
	}

	.p {
		line-height: 1.5;
	}
}
</style>