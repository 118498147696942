<template>
	<div id="tu-information">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu type="1" />
			</el-col>
			<el-col :span="20">
				<div class="tu-title">
					<div>Profile information</div>
					<div></div>
				</div>
				<div>
					<el-table :data="tableData" :show-header="false" style="width: 100%">
				    <el-table-column prop="name" label="name" align="left">
				    	<template #default="scope">
								<div style="font-weight: 600;">{{ scope.row.name }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="edit" label="edit" width="120" align="center">
				    	<template #default="scope">
								<el-button style="width: 100px;" type="danger" @click="$refs[scope.row.value].open()">Edit</el-button>
							</template>
				    </el-table-column>
				    <el-table-column prop="ask" label="ask" width="90" align="center">
				    	<template #default="scope">
								<el-icon style="font-size: 28px;color: #138ce4;"><i class="fa fa-question-circle"></i></el-icon>
							</template>
				    </el-table-column>
				    <el-table-column prop="right" label="right" width="90" align="center">
				    	<template #default="scope">
								<el-icon style="font-size: 22px;color: var(--el-color-success);" v-if="profile[scope.row.value]"><i class="fa fa-check"></i></el-icon>
							</template>
				    </el-table-column>
				  </el-table>
				</div>
				<div v-if="$store.getters.userType==2 && $store.getters.userInfo.is_pass != 1" style="padding: 20px 0;text-align: left;">
					<el-button type="danger" style="width: 220px;" @click="nextStepProgess">Next step</el-button>
				</div>
			</el-col>
		</el-row>
		<Tagline ref="tagline" :profile="profile" />
		<Rate ref="rate" :profile="profile" />
		<Bio ref="bio" :profile="profile" />
		<AvatarUrl ref="avatar_url" :profile="profile" />
		<Qualifications ref="qualifications" :profile="profile" />
		<Subject ref="subject" :profile="profile" />
		<BackgroundCheck ref="background_check" :profile="profile" />
		<QualTeacherStatus ref="qual_teacher_status" :profile="profile" />
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import TeacherApi from '@/api/teacher'
import Tagline from './components/information/tagline'
import Rate from './components/information/rate'
import Bio from './components/information/bio'
import AvatarUrl from './components/information/avatar_url'
import Qualifications from './components/information/qualifications'
import Subject from './components/information/subject'
import BackgroundCheck from './components/information/background_check'
import QualTeacherStatus from './components/information/qual_teacher_status'
export default {
	name: "tu-information",
	components: {
		SecondMenu,
		Tagline,
		Rate,
		Bio,
		AvatarUrl,
		Qualifications,
		Subject,
		BackgroundCheck,
		QualTeacherStatus,
	},
	data() {
		return {
			profile: {},
			tableData: [
			  {
			    name: 'Tagline',
			    value: 'tagline',
			  },
			  {
			    name: 'Rate',
			    value: 'rate',
			  },
			  {
			    name: 'Bio',
			    value: 'bio',
			  },
			  {
			    name: 'Profile picture',
			    value: 'avatar_url',
			  },
			  {
			    name: 'Qualifications',
			    value: 'qualifications',
			  },
			  {
			    name: 'Subjects',
			    value: 'subject',
			  },
			  {
			    name: 'Background check',
			    value: 'background_check',
			  },
			  {
			    name: 'Qualified teacher status',
			    value: 'qual_teacher_status',
			  }
			]
		}
	},
	created() {
		this.getApiInfoData()
	},
	methods: {
		getApiInfoData() {
			TeacherApi.info().then(res => {
				this.profile = res.data
				this.getQualification();
			});
		},
		getQualification() {
			TeacherApi.qualifications().then(res => {
				if(!!res.data[1] || !!res.data[2] || !!res.data[3]) {
					this.profile['qualifications'] = 1;
				}
			})
		},
		nextStepProgess() {
			this.$store.dispatch('GetUserInfo');
			this.$router.push('/tu/availability')
		}
	}
}
</script>
<style lang="less">
#tu-information {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}
</style>