<template>
	<div id="tu-information-rate">
		<el-dialog
	    v-model="dialogVisible"
	    title="Set your hourly rate"
	    width="30%"
	    :before-close="handleClose"
	  >
	  	<p class="p">
	  		This will appear on your profile and the final amount we charge parents and students, a small fee to cover third party withdrawal fee, exchange rate risk and international transfer fees, will be added on top of your rate. Read our advice for setting a competitive hourly rate <a href="/#/teacher/faqs/tthree" target="_blank">here</a>.
	  	</p>
	  	<p class="p">
	  		Our platform fee is deducted from your hourly rate and is reduced as you teach more time with us. Enter your hourly rate to see a breakdown of your earnings below. 
	  	</p>
	    <el-input v-model="content" placeholder="Enter your hourly rate" size="large" type="number">
			<template #prepend>{{ profile.currency || '' }}</template>
		</el-input>
		<div style="margin-top: 5px;">Your hourly rate must be at least {{ profile.currency }}{{ profile.currency == '£' ? '16' : '125' }}</div>
		<h4 style="font-size: 22px;">Your earnings</h4>
		<el-row :gutter="20">
			<el-col :span="6">
				1-249<br />
              	hours taught
			</el-col>
			<el-col :span="18">
				<el-input :value="content > 0 ? (content*0.78).toFixed(2) : 0.00" size="large" :readonly="true">
					<template #prepend>{{ profile.currency || '' }}</template>
				</el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" style="margin-top: 10px;">
			<el-col :span="6">
				250+<br />
              	hours taught
			</el-col>
			<el-col :span="18">
				<el-input :value="content > 0 ? (content*0.83).toFixed(2) : 0.00" :readonly="true" size="large">
					<template #prepend>{{ profile.currency || '' }}</template>
				</el-input>
			</el-col>
		</el-row>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'

export default {
	name: "tu-information-rate",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		
	},
	data() {
		return {
			dialogVisible: false,
			content: 0
		}
	},
	watch: {
		profile() {
			this.content = this.profile.rate || 0
		}
	},
	created() {
		
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		handleSaveOperation() {
			if(!!!this.content) {
				this.$message.error("Relevant information is required");
				return ;
			}
			let params = {
				rate: this.content
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-rate {
	.el-dialog {
		.el-dialog__body {
			padding-top: 5px;
		}
	}
	.el-input {
		.el-input-group__prepend {
			font-weight: 800;
			font-size: 20px;
		}
	}
	.p {
		line-height: 1.5;
	}

	.el-dialog__footer {
		text-align: center;
	}
}
</style>