<template>
	<div id="tu-information-bio">
		<el-dialog
	    v-model="dialogVisible"
	    title="Edit your bio"
	    width="42%"
	  >
	  	<p class="p">Write a professional personal statement, which sets out your experience and teaching style. This is your opportunity to sell yourself to potential clients.</p>
        <p class="p" style="font-weight: 600">Your bio should include:</p>
        <p class="p">
        	<div>A sentence to introduce yourself to students</div>
        	<div>What previous tutoring experience do you have?</div>
        	<div>What is your teaching style/how would you run your lessons?</div>
        	<div>What is your knowledge of the curriculum and exam boards?</div>
        </p>
	  	<el-input v-model="content" placeholder="bio" :rows="15" type="textarea" />
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'

export default {
	name: "tu-information-bio",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		
	},
	data() {
		return {
			dialogVisible: false,
			content: ''
		}
	},
	watch: {
		profile() {
			this.content = this.profile.bio || ''
		}
	},
	created() {
		
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		handleSaveOperation() {
			if(!!!this.content) {
				this.$message.error("Relevant information is required");
				return ;
			}
			let params = {
				bio: this.content
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-bio {
	.el-dialog {
		.el-dialog__body {
			padding-top: 5px;
		}
	}

	.description {
		color: var(--el-color-info);
		padding: 5px;
	}

	.el-dialog__footer {
		text-align: center;
	}

	.p {
		line-height: 1.5;
	}
}
</style>