<template>
	<div id="tu-information-qual_teacher_status">
		<el-dialog
	    v-model="dialogVisible"
	    title="Edit your QTS"
	    width="26%"
	  >
	  	<div class="description">Some students want to find a tutor who is a qualified teacher, but this is not a mandatory requirement to tutor with us.</div>
	  	<el-select v-model="content" class="m-2" placeholder="Select" size="large">
			<el-option
				v-for="item in options"
				:key="item.value"
				:label="item.text"
				:value="item.value">
			</el-option>
		</el-select>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'
import TuUpload from '@/components/TuUpload'

export default {
	name: "tu-information-qual_teacher_status",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		TuUpload
	},
	data() {
		return {
			dialogVisible: false,
			content: '',
			options: [
				{value: null, text: 'Please select an option' },
				{value: 'no', text: 'I do not have a qualified teacher status.'},
				{value: 'pgce', text: 'I have a PGCE qualification.'},
				{value: 'teachfirst', text: 'I have a Teachfirst qualification.'},
				{value: 'scitt', text: 'I have a SCITT qualification.'},
				{value: 'graduate_teacher_program', text: 'I have a Graduate Teacher Program qualification'},
				{value: 'bachelor_of_education', text: 'I have a Bachelor of Education qualification.'}
			]
		}
	},
	watch: {
		profile() {
			this.content = this.profile.qual_teacher_status || ''
		}
	},
	created() {
		
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		handleSaveOperation() {
			if(!!!this.content) {
				this.$message.error("Relevant information is required");
				return ;
			}
			let params = {
				qual_teacher_status: this.content
			}
			TeacherApi.store(params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-qual_teacher_status {
	.el-dialog {
		.el-dialog__body {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.el-dialog__footer {
		text-align: center;
	}

	.description {
		margin: 5px 0;
		line-height: 1.2;
	}

	.el-select {
		width: 100%;
	}
}
</style>