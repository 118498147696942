<template>
	<div id="tu-information-qualifications">
		<el-dialog
	    v-model="dialogVisible"
	    title="Qualifications"
	    width="45%"
	    style="height: 500px"
	  >
	  	<el-tabs v-model="activeName">
			<el-tab-pane label="University" name="University">
				<template v-for="(data, index) in university_data">
					<div v-if="data.deleted==0">
						<el-divider content-position="left">{{ data.name }}</el-divider>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.name" size="large" placeholder="Name"></el-input>
							</el-col>
							<el-col :span="12">
								<el-select v-model="data.grade" style="width: 100%" placeholder="Select" size="large">
								    <el-option
								      v-for="item in university_grade"
								      :key="item.value"
								      :label="item.text"
								      :value="item.value">
								    </el-option>
								</el-select>
							</el-col>
						</el-row>
						<p></p>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.subject" size="large" placeholder="Subject"></el-input>
							</el-col>
							<el-col :span="6">
								<el-checkbox v-model="data.still_studying" value="1" label="Still Studying" size="large"></el-checkbox>
							</el-col>
							<el-col :span="6">
								<el-link v-if="index!=0" style="margin-top: 14px;" type="primary" @click="delUniversity(index)">Remove</el-link>
							</el-col>
						</el-row>
					</div>
				</template>
				<p>
					<el-link type="primary" @click="addUniversity">Add another</el-link>
				</p>
			</el-tab-pane>
			<el-tab-pane label="College" name="College">
				<template v-for="data, index in college_data">
					<div v-if="data.deleted==0">
						<el-divider content-position="left">{{ data.name }}</el-divider>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.name" size="large" placeholder="Name"></el-input>
							</el-col>
							<el-col :span="12">
								<el-select v-model="data.grade" style="width: 100%" placeholder="Select" size="large">
								    <el-option
								      v-for="item in college_grade"
								      :key="item.value"
								      :label="item.text"
								      :value="item.value">
								    </el-option>
								</el-select>
							</el-col>
						</el-row>
						<p></p>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.subject" size="large" placeholder="Subject"></el-input>
							</el-col>
							<el-col :span="6">
								<el-checkbox v-model="data.still_studying" value="1" label="Still Studying" size="large"></el-checkbox>
							</el-col>
							<el-col :span="6">
								<el-link v-if="index!=0" style="margin-top: 14px;" type="primary" @click="delCollege(index)">Remove</el-link>
							</el-col>
						</el-row>
					</div>
				</template>
				<p>
					<el-link type="primary" @click="addCollege">Add another</el-link>
				</p>
			</el-tab-pane>
			<el-tab-pane label="Other" name="Other">
				<template v-for="data, index in other_data">
					<div v-if="data.deleted==0">
						<el-divider content-position="left">{{ data.name }}</el-divider>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.name" size="large" placeholder="Name"></el-input>
							</el-col>
							<el-col :span="12">
								<el-input v-model="data.grade" size="large" placeholder="Grade"></el-input>
							</el-col>
						</el-row>
						<p></p>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-input v-model="data.subject" size="large" placeholder="Subject"></el-input>
							</el-col>
							<el-col :span="6">
								<el-checkbox v-model="data.still_studying" value="1" label="Still Studying" size="large"></el-checkbox>
							</el-col>
							<el-col :span="6">
								<el-link v-if="index!=0" style="margin-top: 14px;" type="primary" @click="delOther(index)">Remove</el-link>
							</el-col>
						</el-row>
					</div>
				</template>
				<p>
					<el-link type="primary" @click="addOther">Add another</el-link>
				</p>
			</el-tab-pane>
		</el-tabs>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="handleSaveOperation">{{ $t('Save') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'

export default {
	name: "tu-information-qualifications",
	props: {
		profile: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	components: {
		
	},
	data() {
		return {
			dialogVisible: false,
			content: [],
			activeName: 'University',
			university_data: [
				{
					name: '',
					grade: '',
					subject: '',
					still_studying: 0,
					deleted: 0
				}
			],
			college_data: [
				{
					name: '',
					grade: '',
					subject: '',
					still_studying: 0,
					deleted: 0
				}
			],
			other_data: [
				{
					name: '',
					grade: '',
					subject: '',
					still_studying: 0,
					deleted: 0
				}
			],
			university_grade: [
				{value: 'degree', text: 'Degree'},
				{value: 'masters', text: 'Masters'},
				{value: 'other', text: 'Other'}
			],
			college_grade: [
		        {value: 'A*', text: 'A*'},
		        {value: 'A', text: 'A'},
		        {value: 'B', text: 'B'},
		        {value: 'C', text: 'C'},
		        {value: 'D', text: 'D'},
		        {value: 'E', text: 'E'},
		    ],
		}
	},
	watch: {
		profile() {
			this.content = this.profile.subject || []
		}
	},
	created() {
		this.getQualification();
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		getQualification() {
			TeacherApi.qualifications().then(res => {
				if(!!res.data[1]) {
					this.university_data = res.data[1];
				}
				if(!!res.data[2]) {
					this.college_data = res.data[2];
				}
				if(!!res.data[3]) {
					this.other_data = res.data[3];
				}
			})
		},
		addUniversity() {
			this.university_data.push({
				name: '',
				grade: '',
				subject: '',
				still_studying: 0,
				deleted: 0
			});
		},
		delUniversity(index) {
			this.university_data[index].deleted = 1;
		},
		addCollege() {
			this.college_data.push({
				name: '',
				grade: '',
				subject: '',
				still_studying: 0,
				deleted: 0
			});
		},
		delCollege(index) {
			this.college_data[index].deleted = 1;
		},
		addOther() {
			this.other_data.push({
				name: '',
				grade: '',
				subject: '',
				still_studying: 0,
				deleted: 0
			});
		},
		delOther(index) {
			this.other_data[index].deleted = 1;
		},
		handleSaveOperation() {
			if(!!!this.content) {
				this.$message.error("Relevant information is required");
				return ;
			}
			let params = {
				university: this.university_data,
				college: this.college_data,
				other: this.other_data
			}
			TeacherApi.qualifications('POST', params).then(res => {
				this.$message.success(this.$t('Save Success'));
				this.dialogVisible = false;
				this.getQualification();
			})
		}
	}
}
</script>
<style lang="less">
#tu-information-qualifications {
	.el-dialog {
		.el-dialog__body {
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	.el-dialog__footer {
		text-align: center;
	}

	.mr-2 {
		margin: 5px 5px 5px 0;
		cursor: pointer;
	}
}
</style>